import React from "react";
import { graphql } from "gatsby";
import SubpageLayout from "../subpage";

export default function TrackerTractLayout({
  children,
  pageContext,
  ...props
}) {
  const data = props.data.allTracts.nodes[0];
  const content = props.data.allLawsuitTrackerJson.nodes[0];
  const meta = pageContext.frontmatter.meta;
  const image = props.data.allFile.nodes[0];

  return <SubpageLayout type="tract" {...{ meta, data, image, content }} />;
}

export const query = graphql`
  query ($geoid: String!) {
    allFile(filter: { name: { eq: "location-hero" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 350)
        }
      }
    }
    allLawsuitTrackerJson {
      nodes {
        tract {
          hero {
            STATS {
              id
              description
            }
            SMALL_CLAIMS_NOTE
          }
          collectors {
            TITLE
            DESCRIPTION
            FOOTNOTE
          }
          lawsuits {
            TITLE
            DESCRIPTION
            PANDEMIC_COMPARISON
            FOOTNOTE
          }
          map {
            TITLE
            DESCRIPTION
            LABEL
            FOOTNOTE
          }
          table {
            TITLE
            DESCRIPTION
            FOOTNOTE
          }
          demographics {
            TITLE
            DESCRIPTION
            BREAKDOWN_TITLE
            BREAKDOWN_LABEL
            COUNT_CHART_TITLE
            COUNT_CHART_TOOLTIP
            PROPORTION_CHART_TITLE
            PROPORTION_CHART_TOOLTIP
            FOOTNOTE
          }
        }
        table {
          LAST_UPDATED
          TOP_LIMIT
          NORTH_DAKOTA_NOTE
          TEXAS_NOTE
          OHIO_NOTE
          PENNSYLVANIA_NOTE
          COUNTIES_NOTE
          TRACTS_NOTE
          ZIPS_NOTE
          STATES_NOTE
          NO_RESULTS
          DEFAULT_JUDGEMENTS_HINT
          REPORT_LINK
        }
      }
    }
    allTracts(filter: { geoid: { eq: $geoid } }) {
      nodes {
        geoid
        name
        state
        lawsuits
        completed_lawsuits
        default_judgement
        # NOTE: temporarily ignore new fields
        # post_judgement
        # engagement
        # answers_filed
        no_rep_percent
        top_collectors {
          amount
          collector
          lawsuits
        }
        collector_total
        lawsuit_history {
          lawsuits
          month
        }
        geoid
        percent_asian
        percent_black
        percent_latinx
        percent_other
        percent_white
        median_hhi
        pct_renter
        pct_owner
        household_size_owner
        household_size_renter
        unemployment_rate
        pct_poverty
        pct_noncitizen
        pct_uninsured
        majority
      }
    }
  }
`;
